@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&family=Poppins:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Proxima+Nova");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .bg-primary-load {
    background: rgb(238, 174, 174);
    background: linear-gradient(
      0deg,
      rgba(238, 174, 174, 1) 0%,
      rgba(148, 187, 233, 1) 100%
    );
  }

  .font-Manrope {
    font-family: "Proxima Nova", sans-serif !important;
    /* font-family: "Manrope", sans-serif !important; */
  }

  .font-Poppins {
    font-family: "Poppins", sans-serif !important;
    /* font-family: "Proxima Nova", sans-serif !important; */
    /* font-size: 18px !important; */
  }

  .font-Proxima-Nova {
    font-family: "Proxima Nova", sans-serif !important;
  }

  .hero_bg {
    background-image: url("./assets/images/webp/HeroBg.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .headless-ui-tab-btn[aria-selected="true"] svg path {
    fill: #fafafa;
  }

  .disclosure-btn[aria-expanded] {
    transition: all 0.4s ease-in-out;
  }

  .disclosure-btn[aria-expanded="true"] span svg {
    transform: rotate(180deg);
  }

  .custom-opacity {
    opacity: 0.4 !important;
  }

  .box_shadow {
    position: relative;
  }

  .testimonials-card {
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));
  }

  .testimonials-card:hover {
    box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.1);
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0));
    border-radius: 18px;
  }

  .box {
    animation: animName 10s linear infinite;
  }

  @keyframes animName {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: #eb2022;
    border-radius: 10px;
  }

  /*FIRST_BUTTON */
  .first_btn::before {
    content: "";
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }

  .first_btn::after {
    content: "";
    background-color: #eb2022 !important;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }

  .first_btn:hover:after {
    content: "";
    height: 0;
    left: 50%;
    top: 50%;
    width: 0;
  }

  .first_btn::before,
  .first_btn::after {
    background-color: white;
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 10px;
  }

  .first_btn:hover {
    color: #eb2022 !important;
  }

  .first_btn,
  .first_btn::after {
    content: "";
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .first_btn {
    background-color: #eb2022;
    border: 1px solid transparent;
    border-radius: 10px;
    color: #fafafa !important;
    display: block;
    padding: 14px 50px;
    position: relative;
    z-index: 9;
  }

  /* =====BUTTON-HOVER===== */
  .common_btn::before {
    content: "";
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }

  .common_btn::after {
    content: "";
    background: white !important;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }

  .common_btn:hover:after {
    content: "";
    height: 0;
    left: 50%;
    top: 50%;
    width: 0;
  }

  .common_btn::before,
  .common_btn::after {
    background: #eb2022;
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 10px;
  }

  .common_btn:hover {
    color: white;
    border: 1px solid #eb2022;
  }

  .common_btn,
  .common_btn::after {
    content: "";
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .common_btn {
    background: #eb2022;
    border: 1px solid #eb2022;
    border-radius: 10px;
    color: #eb2022;
    display: block;
    padding: 14px 50px;
    position: relative;
    z-index: 9;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Works for Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .react-datepicker__day--in-selecting-range,
  datepicker__day,
  .react-datepicker__day--selected {
    background-color: #eb2022 !important;
    color: white !important;
  }

  .react-datepicker-popper {
    z-index: 10;
  }

  .backtotop {
    animation: bounce 1.3s ease-in-out infinite;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0%);
    }

    50% {
      transform: translateY(-25%);
    }

    100% {
      transform: translateY(0%);
    }
  }

  .otp-inputs .otp-input-root {
    gap: 12px;
  }

  .otp-inputs input {
    border: 1.16118px solid rgba(5, 5, 5, 0.24);
    border-radius: 6.9671px;
    background-color: transparent;
    width: 50px !important;
    height: 50px !important;
    margin-right: 0px !important;
  }

  .otp-inputs input:focus {
    border: 1.16118px solid #eb2022 !important;
    outline: none !important;
  }
}

@media (max-width: 575.98px) {
  .otp-inputs input {
    width: 37px !important;
    height: 37px !important;
  }
}
